import './Css/main.css'
import Index from './component/Index';

function App() {
  
  
  return (
    
    <div className="App">
    <Index/>
          


      

    </div>
    
  );
}




export default App;
